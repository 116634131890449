import styles from './Home.module.css';

const Home = () => {
  return (
    <div className={styles['os-homepage']}>
      <h3 className={styles['os-home-header']}>Debut album "Saturnian" coming 2022</h3>
    </div>
  );
};

export default Home;
